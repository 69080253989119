/*
This is a temporary, hacky override to make error message
text below more visually prominent.
*/
.nds-input-support-text,
.nds-dropdown-support-text {
  font-size: 16px !important;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-left-slot,
.header-right-slot {
  flex: 0.007;
}

.header-center-slot {
  flex: 1;
  text-align: center;
  margin-right: -32px;
}

.header-right-slot {
  text-align: center;
  padding-right: 32px;
}
@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }
  .header-center-slot {
    margin-right: 0px;
  }
  .header-right-slot {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    margin-bottom: -32px;
  }
}

div.dialogModal {
  max-width: clamp(350px, 40vw, 800px);
}
